@charset "UTF-8";
body {
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 300;
    font-size: 1rem;
    color: #41494f;
    background-color: #fff;
    overflow-x: hidden;
    text-rendering: optimizeLegibility!important;
    -webkit-font-smoothing: antialiased!important
}

a {
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in
}

a, a:active, a:focus, a:hover {
    outline: 0!important;
    text-decoration: none
}

::selection {
    background: #7b68ee;
    color: #41494f;
    text-shadow: none
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #edf4f8;
    margin: 1em 0;
    padding: 0
}

audio, canvas, iframe, img, svg, video {
    vertical-align: middle
}

fieldset {
    min-width: 0;
    border: 0;
    margin: 0;
    padding: 0
}

textarea {
    resize: vertical
}

.browserupgrade {
    margin: .2em 0;
    background: #ccc;
    color: #000;
    padding: .2em 0
}

*, ::after, ::before {
    box-sizing: border-box
}

.nav-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;
    opacity: 0;
    -webkit-transition: opacity .4s ease 0s;
    transition: opacity .4s ease 0s
}

.navigation {
    background-color: #fff;
    min-height: 90px;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

@media (max-width:767.98px) {
    .navigation .container {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between
    }
}

.navigation .navbar-toggler {
    align-self: center
}

.navigation .navbar-toggler .icon-bar {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    background-color: #7b68ee;
    -webkit-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out;
    display: block;
    width: 30px;
    height: 2px
}

.navigation .navbar-toggler .icon-bar+.icon-bar {
    margin-top: 6px
}

.navigation .navbar-toggler .icon-bar:hover {
    background-color: #3f23e6
}

.navigation.fixed-top .navbar-collapse {
    height: 100vh
}

.navigation.navbar-expanded.sidebar-left .collapse {
    left: 0
}

.navigation.navbar-expanded.sidebar-left .navbar-brand, .navigation.navbar-expanded.sidebar-left .navbar-toggler {
    -webkit-transform: translateX(250px);
    transform: translateX(250px)
}

.navigation.navbar-expanded.sidebar-right .collapse {
    right: 0
}

.navigation.navbar-expanded.sidebar-right .navbar-brand, .navigation.navbar-expanded.sidebar-right .navbar-toggler {
    -webkit-transform: translateX(-250px);
    transform: translateX(-250px)
}

.navigation.navbar-expanded .icon-bar:nth-child(1) {
    -webkit-transform: rotate(-225deg);
    transform: rotate(-225deg)
}

.navigation.navbar-expanded .icon-bar:nth-child(2) {
    -webkit-transform: translateX(-40px);
    transform: translateX(-40px);
    opacity: 0
}

.navigation.navbar-expanded .icon-bar:nth-child(3) {
    -webkit-transform: translateY(-15px) rotate(225deg);
    transform: translateY(-15px) rotate(225deg)
}

.navigation.navbar-expanded~.nav-overlay {
    opacity: 1;
    z-index: 1029
}

.navigation.sidebar-left .collapse {
    left: -250px;
    border-right: 1px solid #edf4f8
}

.navigation.sidebar-right .collapse {
    right: -250px;
    border-left: 1px solid #edf4f8
}

.navigation.sidebar-right .navbar-toggler {
    order: 2
}

.navigation.sidebar-right .navbar-brand {
    order: 1
}

.navigation .collapse {
    -webkit-transition: left .4s ease 0s;
    transition: left .4s ease 0s;
    background-color: #fff;
    width: 250px;
    position: fixed;
    margin: 0;
    top: 0;
    bottom: 0;
    display: block;
    overflow-y: auto
}

@media (max-width:767.98px) {
    .navigation .collapse {
        overflow-y: auto
    }
}

.navigation .logo {
    max-height: 60px
}

.navigation .sidebar-brand {
    padding: 1rem
}

.navigation .nav-item.active .nav-link, .navigation .nav-item:hover .nav-link {
    color: #fff
}

@media (max-width:767.98px) {
    .navigation .nav-item.active .nav-link, .navigation .nav-item:hover .nav-link {
        background: #7b68ee
    }
}

.navigation .nav-item .nav-link {
    color: #7b68ee;
    font-family: Lato, "Open Sans", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: .85rem;
    font-weight: 700;
    -webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out, opacity .3s ease-in-out;
    transition: color .3s ease-in-out, background-color .3s ease-in-out, opacity .3s ease-in-out;
    text-decoration: none;
    letter-spacing: 1px;
    padding: 1rem
}

.navigation .nav-item .nav-link i {
    display: inline-block;
    margin-right: 15px
}

.navigation.navbar-sticky {
    position: fixed!important;
    background-color: #fff;
    left: 0;
    top: 0;
    right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .11);
    box-shadow: 0 0 5px rgba(0, 0, 0, .11);
    min-height: 90px;
    z-index: 1030;
    -webkit-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out
}

.navigation .btn-outline, .navigation.navbar-sticky .btn-outline {
    color: #7b68ee;
    background-color: transparent;
    background-image: none;
    border-color: #7b68ee
}

.navigation .btn-outline:hover, .navigation.navbar-sticky .btn-outline:hover {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.navigation .btn-outline.focus, .navigation .btn-outline:focus, .navigation.navbar-sticky .btn-outline.focus, .navigation.navbar-sticky .btn-outline:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.navigation .btn-outline.disabled, .navigation .btn-outline:disabled, .navigation.navbar-sticky .btn-outline.disabled, .navigation.navbar-sticky .btn-outline:disabled {
    color: #7b68ee;
    background-color: transparent
}

.navigation .btn-outline:not(:disabled):not(.disabled).active, .navigation .btn-outline:not(:disabled):not(.disabled):active, .navigation.navbar-sticky .btn-outline:not(:disabled):not(.disabled).active, .navigation.navbar-sticky .btn-outline:not(:disabled):not(.disabled):active, .show>.navigation .btn-outline.dropdown-toggle, .show>.navigation.navbar-sticky .btn-outline.dropdown-toggle {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.navigation .btn-outline:not(:disabled):not(.disabled).active:focus, .navigation .btn-outline:not(:disabled):not(.disabled):active:focus, .navigation.navbar-sticky .btn-outline:not(:disabled):not(.disabled).active:focus, .navigation.navbar-sticky .btn-outline:not(:disabled):not(.disabled):active:focus, .show>.navigation .btn-outline.dropdown-toggle:focus, .show>.navigation.navbar-sticky .btn-outline.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.navigation .btn-solid, .navigation.navbar-sticky .btn-solid {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.navigation .btn-solid:hover, .navigation.navbar-sticky .btn-solid:hover {
    color: #fff;
    background-color: #5d46ea;
    border-color: #533ae9
}

.navigation .btn-solid.focus, .navigation .btn-solid:focus, .navigation.navbar-sticky .btn-solid.focus, .navigation.navbar-sticky .btn-solid:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.navigation .btn-solid.disabled, .navigation .btn-solid:disabled, .navigation.navbar-sticky .btn-solid.disabled, .navigation.navbar-sticky .btn-solid:disabled {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.navigation .btn-solid:not(:disabled):not(.disabled).active, .navigation .btn-solid:not(:disabled):not(.disabled):active, .navigation.navbar-sticky .btn-solid:not(:disabled):not(.disabled).active, .navigation.navbar-sticky .btn-solid:not(:disabled):not(.disabled):active, .show>.navigation .btn-solid.dropdown-toggle, .show>.navigation.navbar-sticky .btn-solid.dropdown-toggle {
    color: #fff;
    background-color: #533ae9;
    border-color: #492fe8
}

.navigation .btn-solid:not(:disabled):not(.disabled).active:focus, .navigation .btn-solid:not(:disabled):not(.disabled):active:focus, .navigation.navbar-sticky .btn-solid:not(:disabled):not(.disabled).active:focus, .navigation.navbar-sticky .btn-solid:not(:disabled):not(.disabled):active:focus, .show>.navigation .btn-solid.dropdown-toggle:focus, .show>.navigation.navbar-sticky .btn-solid.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.navigation.navbar-sticky .logo {
    display: none
}

.navigation.navbar-sticky .logo.logo-sticky {
    display: block!important
}

.navigation.navbar-sticky .logo.logo-sticky+.logo {
    display: none!important
}

@media (min-width:768px) {
    .navigation {
        background-color: #fff;
        padding: 0
    }
    .navigation:not(.fixed-top) {
        position: static;
        top: -90px
    }
    .navigation.fixed-top {
        background-color: transparent
    }
    .navigation.fixed-top .navbar-collapse {
        height: auto
    }
    .navigation.fixed-top .nav-item .nav-link {
        color: #fff
    }
    .navigation.fixed-top .nav-item.active, .navigation.fixed-top .nav-item:hover {
        color: #d9d9d9
    }
    .navigation.navbar-sticky {
        background-color: #fff;
        top: 0
    }
    .navigation.navbar-sticky .nav-item .nav-link {
        color: #646f79
    }
    .navigation.navbar-sticky .nav-item.active .nav-link, .navigation.navbar-sticky .nav-item:hover .nav-link {
        color: #7b68ee
    }
    .navigation.navbar-expanded .navbar-brand, .navigation.navbar-expanded .navbar-toggler, .navigation.navbar-expanded~main {
        -webkit-transform: translateX(0)!important;
        transform: translateX(0)!important
    }
    .navigation.sidebar-left .collapse {
        left: 0;
        border-right: none
    }
    .navigation.sidebar-right .collapse {
        right: 0;
        border-left: none
    }
    .navigation.sidebar-right .navbar-brand {
        order: 0
    }
    .navigation.dark-link .nav-item .nav-link {
        color: #143350;
        opacity: .7
    }
    .navigation.dark-link .nav-item.active .nav-link, .navigation.dark-link .nav-item:hover .nav-link {
        opacity: 1;
        color: #050c13
    }
    .navigation.dark-link .btn-outline {
        color: #7b68ee;
        background-color: transparent;
        background-image: none;
        border-color: #7b68ee
    }
    .navigation.dark-link .btn-outline:hover {
        color: #fff;
        background-color: #7b68ee;
        border-color: #7b68ee
    }
    .navigation.dark-link .btn-outline.focus, .navigation.dark-link .btn-outline:focus {
        box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
    }
    .navigation.dark-link .btn-outline.disabled, .navigation.dark-link .btn-outline:disabled {
        color: #7b68ee;
        background-color: transparent
    }
    .navigation.dark-link .btn-outline:not(:disabled):not(.disabled).active, .navigation.dark-link .btn-outline:not(:disabled):not(.disabled):active, .show>.navigation.dark-link .btn-outline.dropdown-toggle {
        color: #fff;
        background-color: #7b68ee;
        border-color: #7b68ee
    }
    .navigation.dark-link .btn-outline:not(:disabled):not(.disabled).active:focus, .navigation.dark-link .btn-outline:not(:disabled):not(.disabled):active:focus, .show>.navigation.dark-link .btn-outline.dropdown-toggle:focus {
        box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
    }
    .navigation.dark-link .btn-solid {
        color: #fff;
        background-color: #7b68ee;
        border-color: #7b68ee
    }
    .navigation.dark-link .btn-solid:hover {
        color: #fff;
        background-color: #5d46ea;
        border-color: #533ae9
    }
    .navigation.dark-link .btn-solid.focus, .navigation.dark-link .btn-solid:focus {
        box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
    }
    .navigation.dark-link .btn-solid.disabled, .navigation.dark-link .btn-solid:disabled {
        color: #fff;
        background-color: #7b68ee;
        border-color: #7b68ee
    }
    .navigation.dark-link .btn-solid:not(:disabled):not(.disabled).active, .navigation.dark-link .btn-solid:not(:disabled):not(.disabled):active, .show>.navigation.dark-link .btn-solid.dropdown-toggle {
        color: #fff;
        background-color: #533ae9;
        border-color: #492fe8
    }
    .navigation.dark-link .btn-solid:not(:disabled):not(.disabled).active:focus, .navigation.dark-link .btn-solid:not(:disabled):not(.disabled):active:focus, .show>.navigation.dark-link .btn-solid.dropdown-toggle:focus {
        box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
    }
    .navigation .collapse {
        position: relative;
        width: auto;
        background: 0 0
    }
    .navigation .nav-item .nav-link {
        color: #41494f;
        padding: 34px 15px;
        height: 90px
    }
    .navigation .nav-item .nav-link i {
        display: none
    }
    .navigation .nav-item.active .nav-link, .navigation .nav-item:hover .nav-link {
        color: #363c41
    }
    .navigation .sidebar-brand {
        display: none
    }
    .navigation .btn-outline {
        color: #fff;
        background-color: transparent;
        background-image: none;
        border-color: #fff;
        color: #fff
    }
    .navigation .btn-outline:hover {
        color: #212529;
        background-color: #fff;
        border-color: #fff
    }
    .navigation .btn-outline.focus, .navigation .btn-outline:focus {
        box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
    }
    .navigation .btn-outline.disabled, .navigation .btn-outline:disabled {
        color: #fff;
        background-color: transparent
    }
    .navigation .btn-outline:not(:disabled):not(.disabled).active, .navigation .btn-outline:not(:disabled):not(.disabled):active, .show>.navigation .btn-outline.dropdown-toggle {
        color: #212529;
        background-color: #fff;
        border-color: #fff
    }
    .navigation .btn-outline:not(:disabled):not(.disabled).active:focus, .navigation .btn-outline:not(:disabled):not(.disabled):active:focus, .show>.navigation .btn-outline.dropdown-toggle:focus {
        box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
    }
    .navigation .btn-solid {
        color: #212529;
        background-color: #fff;
        border-color: #fff
    }
    .navigation .btn-solid:hover {
        color: #212529;
        background-color: #ececec;
        border-color: #e6e6e6
    }
    .navigation .btn-solid.focus, .navigation .btn-solid:focus {
        box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
    }
    .navigation .btn-solid.disabled, .navigation .btn-solid:disabled {
        color: #212529;
        background-color: #fff;
        border-color: #fff
    }
    .navigation .btn-solid:not(:disabled):not(.disabled).active, .navigation .btn-solid:not(:disabled):not(.disabled):active, .show>.navigation .btn-solid.dropdown-toggle {
        color: #212529;
        background-color: #e6e6e6;
        border-color: #dfdfdf
    }
    .navigation .btn-solid:not(:disabled):not(.disabled).active:focus, .navigation .btn-solid:not(:disabled):not(.disabled):active:focus, .show>.navigation .btn-solid.dropdown-toggle:focus {
        box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
    }
    .navigation .dropdown-menu {
        margin-top: -34px
    }
}

.nav-pills-light .nav-link {
    opacity: .7;
    color: #1f2225;
    border: 1px solid transparent
}

.nav-pills-light .nav-link.active, .nav-pills-light .nav-link:hover {
    opacity: 1
}

.nav-pills-light .nav-link.active {
    background-color: #fff;
    color: #1f2225;
    box-shadow: 0 0 64px rgba(188, 212, 231, .5);
    border: 1px solid #edf4f8
}

.nav-pills-outline .nav-link {
    border-radius: 0;
    padding: .5rem;
    position: relative
}

.nav-pills-outline .nav-link:after {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .3s transform ease;
    transition: .3s transform ease;
    content: '';
    height: 2px;
    background-color: #007bff;
    position: absolute;
    left: 25%;
    bottom: 0;
    width: 50%
}

.nav-pills-outline .nav-link.active {
    background-color: #fff;
    color: #007bff
}

.nav-pills-outline .nav-link.active:after {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.nav-outline {
    justify-content: space-between
}

@media (min-width:576px) {
    .nav-outline {
        justify-content: center
    }
}

.nav-outline .nav-link {
    border: 1px solid #7b68ee;
    text-align: center;
    justify-content: center;
    padding: 4px 10px;
    letter-spacing: 1px;
    margin-top: .5rem;
    color: #7b68ee
}

@media (min-width:576px) {
    .nav-outline .nav-link+.nav-link {
        margin-left: -1px
    }
}

@media (min-width:768px) {
    .nav-outline .nav-link {
        margin-top: 0;
        padding: 4px 20px;
        font-size: 1rem
    }
}

.nav-outline .nav-link.active {
    background-color: #7b68ee;
    color: #fff
}

.nav-outline .nav-link:last-child {
    border-right: 1px solid #7b68ee
}

.nav-outline.nav-rounded .nav-link {
    border-radius: 5rem;
    margin-right: .5rem
}

@media (min-width:768px) {
    .nav-outline.nav-rounded .nav-link {
        border-radius: 0;
        margin-right: 0
    }
    .nav-outline.nav-rounded .nav-link:first-child {
        border-radius: 5rem 0 0 5rem
    }
    .nav-outline.nav-rounded .nav-link:last-child {
        border-radius: 0 5rem 5rem 0
    }
}

.form[data-response-message-animation], .form[data-response-message-animation]+.response-message, form[data-response-message-animation], form[data-response-message-animation]+.response-message {
    -webkit-transition: opacity .3s ease-out, -webkit-transform .3s ease-in;
    transition: opacity .3s ease-out, -webkit-transform .3s ease-in;
    transition: transform .3s ease-in, opacity .3s ease-out;
    transition: transform .3s ease-in, opacity .3s ease-out, -webkit-transform .3s ease-in
}

.form[data-response-message-animation].submitted+.response-message, form[data-response-message-animation].submitted+.response-message {
    opacity: 1;
    position: relative
}

.form[data-response-message-animation]+.response-message, .form[data-response-message-animation].submitted, form[data-response-message-animation]+.response-message, form[data-response-message-animation].submitted {
    opacity: 0;
    position: absolute
}

.form[data-response-message-animation=slide-in-up].submitted+.response-message, form[data-response-message-animation=slide-in-up].submitted+.response-message {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.form[data-response-message-animation=slide-in-up]+.response-message, .form[data-response-message-animation=slide-in-up].submitted, form[data-response-message-animation=slide-in-up]+.response-message, form[data-response-message-animation=slide-in-up].submitted {
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

.form[data-response-message-animation=slide-in-left].submitted+.response-message, form[data-response-message-animation=slide-in-left].submitted+.response-message {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.form[data-response-message-animation=slide-in-left]+.response-message, .form[data-response-message-animation=slide-in-left].submitted, form[data-response-message-animation=slide-in-left]+.response-message, form[data-response-message-animation=slide-in-left].submitted {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.form.cozy .form-group, form.cozy .form-group {
    margin-bottom: 30px
}

.form .form-group label.error, form .form-group label.error {
    color: #dc3545;
    font-size: .65rem
}

button:focus {
    outline: 0
}

.btn {
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    text-decoration: none;
    letter-spacing: 1.25px;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .26);
    display: block
}

.btn .icon {
    margin-right: 8px
}

.btn-link {
    box-shadow: none;
    cursor: pointer
}

.btn-rounded {
    border-radius: 50px
}

.btn-circle {
    border-radius: 50%
}

.btn-download {
    width: 200px;
    text-align: left;
    display: flex!important;
    align-items: center
}

.input-group .btn:not(:first-child) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.btn-1 {
    color: #212529;
    background-color: #fff;
    border-color: #fff
}

.btn-1:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6
}

.btn-1.focus, .btn-1:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.btn-1.disabled, .btn-1:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff
}

.btn-1:not(:disabled):not(.disabled).active, .btn-1:not(:disabled):not(.disabled):active, .show>.btn-1.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf
}

.btn-1:not(:disabled):not(.disabled).active:focus, .btn-1:not(:disabled):not(.disabled):active:focus, .show>.btn-1.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.btn-outline-1 {
    color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: #fff
}

.btn-outline-1:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff
}

.btn-outline-1.focus, .btn-outline-1:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.btn-outline-1.disabled, .btn-outline-1:disabled {
    color: #fff;
    background-color: transparent
}

.btn-outline-1:not(:disabled):not(.disabled).active, .btn-outline-1:not(:disabled):not(.disabled):active, .show>.btn-outline-1.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff
}

.btn-outline-1:not(:disabled):not(.disabled).active:focus, .btn-outline-1:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-1.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.btn-2 {
    color: #fff;
    background-color: #646f79;
    border-color: #646f79
}

.btn-2:hover {
    color: #fff;
    background-color: #535c64;
    border-color: #4d555d
}

.btn-2.focus, .btn-2:focus {
    box-shadow: 0 0 0 .2rem rgba(100, 111, 121, .5)
}

.btn-2.disabled, .btn-2:disabled {
    color: #fff;
    background-color: #646f79;
    border-color: #646f79
}

.btn-2:not(:disabled):not(.disabled).active, .btn-2:not(:disabled):not(.disabled):active, .show>.btn-2.dropdown-toggle {
    color: #fff;
    background-color: #4d555d;
    border-color: #474f56
}

.btn-2:not(:disabled):not(.disabled).active:focus, .btn-2:not(:disabled):not(.disabled):active:focus, .show>.btn-2.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(100, 111, 121, .5)
}

.btn-outline-2 {
    color: #646f79;
    background-color: transparent;
    background-image: none;
    border-color: #646f79
}

.btn-outline-2:hover {
    color: #fff;
    background-color: #646f79;
    border-color: #646f79
}

.btn-outline-2.focus, .btn-outline-2:focus {
    box-shadow: 0 0 0 .2rem rgba(100, 111, 121, .5)
}

.btn-outline-2.disabled, .btn-outline-2:disabled {
    color: #646f79;
    background-color: transparent
}

.btn-outline-2:not(:disabled):not(.disabled).active, .btn-outline-2:not(:disabled):not(.disabled):active, .show>.btn-outline-2.dropdown-toggle {
    color: #fff;
    background-color: #646f79;
    border-color: #646f79
}

.btn-outline-2:not(:disabled):not(.disabled).active:focus, .btn-outline-2:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-2.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(100, 111, 121, .5)
}

.btn-3 {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.btn-3:hover {
    color: #fff;
    background-color: #5d46ea;
    border-color: #533ae9
}

.btn-3.focus, .btn-3:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.btn-3.disabled, .btn-3:disabled {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.btn-3:not(:disabled):not(.disabled).active, .btn-3:not(:disabled):not(.disabled):active, .show>.btn-3.dropdown-toggle {
    color: #fff;
    background-color: #533ae9;
    border-color: #492fe8
}

.btn-3:not(:disabled):not(.disabled).active:focus, .btn-3:not(:disabled):not(.disabled):active:focus, .show>.btn-3.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.btn-outline-3 {
    color: #7b68ee;
    background-color: transparent;
    background-image: none;
    border-color: #7b68ee
}

.btn-outline-3:hover {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.btn-outline-3.focus, .btn-outline-3:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.btn-outline-3.disabled, .btn-outline-3:disabled {
    color: #7b68ee;
    background-color: transparent
}

.btn-outline-3:not(:disabled):not(.disabled).active, .btn-outline-3:not(:disabled):not(.disabled):active, .show>.btn-outline-3.dropdown-toggle {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.btn-outline-3:not(:disabled):not(.disabled).active:focus, .btn-outline-3:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-3.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.btn-4 {
    color: #fff;
    background-color: #017dc3;
    border-color: #017dc3
}

.btn-4:hover {
    color: #fff;
    background-color: #01659d;
    border-color: #015c90
}

.btn-4.focus, .btn-4:focus {
    box-shadow: 0 0 0 .2rem rgba(1, 125, 195, .5)
}

.btn-4.disabled, .btn-4:disabled {
    color: #fff;
    background-color: #017dc3;
    border-color: #017dc3
}

.btn-4:not(:disabled):not(.disabled).active, .btn-4:not(:disabled):not(.disabled):active, .show>.btn-4.dropdown-toggle {
    color: #fff;
    background-color: #015c90;
    border-color: #015484
}

.btn-4:not(:disabled):not(.disabled).active:focus, .btn-4:not(:disabled):not(.disabled):active:focus, .show>.btn-4.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(1, 125, 195, .5)
}

.btn-outline-4 {
    color: #017dc3;
    background-color: transparent;
    background-image: none;
    border-color: #017dc3
}

.btn-outline-4:hover {
    color: #fff;
    background-color: #017dc3;
    border-color: #017dc3
}

.btn-outline-4.focus, .btn-outline-4:focus {
    box-shadow: 0 0 0 .2rem rgba(1, 125, 195, .5)
}

.btn-outline-4.disabled, .btn-outline-4:disabled {
    color: #017dc3;
    background-color: transparent
}

.btn-outline-4:not(:disabled):not(.disabled).active, .btn-outline-4:not(:disabled):not(.disabled):active, .show>.btn-outline-4.dropdown-toggle {
    color: #fff;
    background-color: #017dc3;
    border-color: #017dc3
}

.btn-outline-4:not(:disabled):not(.disabled).active:focus, .btn-outline-4:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-4.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(1, 125, 195, .5)
}

.btn-5 {
    color: #fff;
    background-color: #143350;
    border-color: #143350
}

.btn-5:hover {
    color: #fff;
    background-color: #0c1f31;
    border-color: #0a1927
}

.btn-5.focus, .btn-5:focus {
    box-shadow: 0 0 0 .2rem rgba(20, 51, 80, .5)
}

.btn-5.disabled, .btn-5:disabled {
    color: #fff;
    background-color: #143350;
    border-color: #143350
}

.btn-5:not(:disabled):not(.disabled).active, .btn-5:not(:disabled):not(.disabled):active, .show>.btn-5.dropdown-toggle {
    color: #fff;
    background-color: #0a1927;
    border-color: #07121d
}

.btn-5:not(:disabled):not(.disabled).active:focus, .btn-5:not(:disabled):not(.disabled):active:focus, .show>.btn-5.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(20, 51, 80, .5)
}

.btn-outline-5 {
    color: #143350;
    background-color: transparent;
    background-image: none;
    border-color: #143350
}

.btn-outline-5:hover {
    color: #fff;
    background-color: #143350;
    border-color: #143350
}

.btn-outline-5.focus, .btn-outline-5:focus {
    box-shadow: 0 0 0 .2rem rgba(20, 51, 80, .5)
}

.btn-outline-5.disabled, .btn-outline-5:disabled {
    color: #143350;
    background-color: transparent
}

.btn-outline-5:not(:disabled):not(.disabled).active, .btn-outline-5:not(:disabled):not(.disabled):active, .show>.btn-outline-5.dropdown-toggle {
    color: #fff;
    background-color: #143350;
    border-color: #143350
}

.btn-outline-5:not(:disabled):not(.disabled).active:focus, .btn-outline-5:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-5.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(20, 51, 80, .5)
}

.btn-6 {
    color: #212529;
    background-color: #f4f8fb;
    border-color: #f4f8fb
}

.btn-6:hover {
    color: #212529;
    background-color: #d8e6f1;
    border-color: #cfe0ed
}

.btn-6.focus, .btn-6:focus {
    box-shadow: 0 0 0 .2rem rgba(244, 248, 251, .5)
}

.btn-6.disabled, .btn-6:disabled {
    color: #212529;
    background-color: #f4f8fb;
    border-color: #f4f8fb
}

.btn-6:not(:disabled):not(.disabled).active, .btn-6:not(:disabled):not(.disabled):active, .show>.btn-6.dropdown-toggle {
    color: #212529;
    background-color: #cfe0ed;
    border-color: #c5daea
}

.btn-6:not(:disabled):not(.disabled).active:focus, .btn-6:not(:disabled):not(.disabled):active:focus, .show>.btn-6.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(244, 248, 251, .5)
}

.btn-outline-6 {
    color: #f4f8fb;
    background-color: transparent;
    background-image: none;
    border-color: #f4f8fb
}

.btn-outline-6:hover {
    color: #212529;
    background-color: #f4f8fb;
    border-color: #f4f8fb
}

.btn-outline-6.focus, .btn-outline-6:focus {
    box-shadow: 0 0 0 .2rem rgba(244, 248, 251, .5)
}

.btn-outline-6.disabled, .btn-outline-6:disabled {
    color: #f4f8fb;
    background-color: transparent
}

.btn-outline-6:not(:disabled):not(.disabled).active, .btn-outline-6:not(:disabled):not(.disabled):active, .show>.btn-outline-6.dropdown-toggle {
    color: #212529;
    background-color: #f4f8fb;
    border-color: #f4f8fb
}

.btn-outline-6:not(:disabled):not(.disabled).active:focus, .btn-outline-6:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-6.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(244, 248, 251, .5)
}

.btn-accent {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.btn-accent:hover {
    color: #fff;
    background-color: #5d46ea;
    border-color: #533ae9
}

.btn-accent.focus, .btn-accent:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.btn-accent.disabled, .btn-accent:disabled {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.btn-accent:not(:disabled):not(.disabled).active, .btn-accent:not(:disabled):not(.disabled):active, .show>.btn-accent.dropdown-toggle {
    color: #fff;
    background-color: #533ae9;
    border-color: #492fe8
}

.btn-accent:not(:disabled):not(.disabled).active:focus, .btn-accent:not(:disabled):not(.disabled):active:focus, .show>.btn-accent.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.btn-outline-accent {
    color: #7b68ee;
    background-color: transparent;
    background-image: none;
    border-color: #7b68ee
}

.btn-outline-accent:hover {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.btn-outline-accent.focus, .btn-outline-accent:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.btn-outline-accent.disabled, .btn-outline-accent:disabled {
    color: #7b68ee;
    background-color: transparent
}

.btn-outline-accent:not(:disabled):not(.disabled).active, .btn-outline-accent:not(:disabled):not(.disabled):active, .show>.btn-outline-accent.dropdown-toggle {
    color: #fff;
    background-color: #7b68ee;
    border-color: #7b68ee
}

.btn-outline-accent:not(:disabled):not(.disabled).active:focus, .btn-outline-accent:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-accent.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(123, 104, 238, .5)
}

.btn-outline-1, .btn-outline-1:hover {
    color: #143350
}

a.btn {
    display: inline-block
}

.image-background {
    position: relative;
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat
}

.image-background.cover {
    background-size: cover
}

.image-background.contain {
    background-size: contain
}

.image-background.center-top {
    background-position: center top
}

.image-background.center-bottom {
    background-position: center bottom
}

.image-background.p100-top {
    background-position: 100% top
}

.image-background.p100-bottom {
    background-position: 100% bottom
}

.parallax {
    position: static
}

.parallax, .parallax.blurred:before {
    background-attachment: fixed
}

.parallax.blurred:before {
    position: absolute
}

.bg-1 {
    background-color: #fff
}

.bg-1.alpha-1 {
    background-color: rgba(255, 255, 255, .1)
}

.bg-1.alpha-2 {
    background-color: rgba(255, 255, 255, .2)
}

.bg-1.alpha-3 {
    background-color: rgba(255, 255, 255, .3)
}

.bg-1.alpha-4 {
    background-color: rgba(255, 255, 255, .4)
}

.bg-1.alpha-5 {
    background-color: rgba(255, 255, 255, .5)
}

.bg-1.alpha-6 {
    background-color: rgba(255, 255, 255, .6)
}

.bg-1.alpha-7 {
    background-color: rgba(255, 255, 255, .7)
}

.bg-1.alpha-8 {
    background-color: rgba(255, 255, 255, .8)
}

.bg-1.alpha-9 {
    background-color: rgba(255, 255, 255, .9)
}

.bg-1-gradient {
    background: -webkit-gradient(linear, left top, left bottom, from(white), to(#fff));
    background: linear-gradient(white, #fff)
}

.bg-1-gradient-start {
    background: #fff
}

.bg-2 {
    background-color: #646f79
}

.bg-2.alpha-1 {
    background-color: rgba(100, 111, 121, .1)
}

.bg-2.alpha-2 {
    background-color: rgba(100, 111, 121, .2)
}

.bg-2.alpha-3 {
    background-color: rgba(100, 111, 121, .3)
}

.bg-2.alpha-4 {
    background-color: rgba(100, 111, 121, .4)
}

.bg-2.alpha-5 {
    background-color: rgba(100, 111, 121, .5)
}

.bg-2.alpha-6 {
    background-color: rgba(100, 111, 121, .6)
}

.bg-2.alpha-7 {
    background-color: rgba(100, 111, 121, .7)
}

.bg-2.alpha-8 {
    background-color: rgba(100, 111, 121, .8)
}

.bg-2.alpha-9 {
    background-color: rgba(100, 111, 121, .9)
}

.bg-2-gradient {
    background: -webkit-gradient(linear, left top, left bottom, from(#66727c), to(#646f79));
    background: linear-gradient(#66727c, #646f79)
}

.bg-2-gradient-start {
    background: #66727c
}

.bg-3 {
    background-color: #7b68ee
}

.bg-3.alpha-1 {
    background-color: rgba(123, 104, 238, .1)
}

.bg-3.alpha-2 {
    background-color: rgba(123, 104, 238, .2)
}

.bg-3.alpha-3 {
    background-color: rgba(123, 104, 238, .3)
}

.bg-3.alpha-4 {
    background-color: rgba(123, 104, 238, .4)
}

.bg-3.alpha-5 {
    background-color: rgba(123, 104, 238, .5)
}

.bg-3.alpha-6 {
    background-color: rgba(123, 104, 238, .6)
}

.bg-3.alpha-7 {
    background-color: rgba(123, 104, 238, .7)
}

.bg-3.alpha-8 {
    background-color: rgba(123, 104, 238, .8)
}

.bg-3.alpha-9 {
    background-color: rgba(123, 104, 238, .9)
}

.bg-3-gradient {
    background: -webkit-gradient(linear, left top, left bottom, from(#7f6def), to(#7b68ee));
    background: linear-gradient(#7f6def, #7b68ee)
}

.bg-3-gradient-start {
    background: #7f6def
}

.bg-4 {
    background-color: #017dc3
}

.bg-4.alpha-1 {
    background-color: rgba(1, 125, 195, .1)
}

.bg-4.alpha-2 {
    background-color: rgba(1, 125, 195, .2)
}

.bg-4.alpha-3 {
    background-color: rgba(1, 125, 195, .3)
}

.bg-4.alpha-4 {
    background-color: rgba(1, 125, 195, .4)
}

.bg-4.alpha-5 {
    background-color: rgba(1, 125, 195, .5)
}

.bg-4.alpha-6 {
    background-color: rgba(1, 125, 195, .6)
}

.bg-4.alpha-7 {
    background-color: rgba(1, 125, 195, .7)
}

.bg-4.alpha-8 {
    background-color: rgba(1, 125, 195, .8)
}

.bg-4.alpha-9 {
    background-color: rgba(1, 125, 195, .9)
}

.bg-4-gradient {
    background: -webkit-gradient(linear, left top, left bottom, from(#0180c8), to(#017dc3));
    background: linear-gradient(#0180c8, #017dc3)
}

.bg-4-gradient-start {
    background: #0180c8
}

.bg-5 {
    background-color: #143350
}

.bg-5.alpha-1 {
    background-color: rgba(20, 51, 80, .1)
}

.bg-5.alpha-2 {
    background-color: rgba(20, 51, 80, .2)
}

.bg-5.alpha-3 {
    background-color: rgba(20, 51, 80, .3)
}

.bg-5.alpha-4 {
    background-color: rgba(20, 51, 80, .4)
}

.bg-5.alpha-5 {
    background-color: rgba(20, 51, 80, .5)
}

.bg-5.alpha-6 {
    background-color: rgba(20, 51, 80, .6)
}

.bg-5.alpha-7 {
    background-color: rgba(20, 51, 80, .7)
}

.bg-5.alpha-8 {
    background-color: rgba(20, 51, 80, .8)
}

.bg-5.alpha-9 {
    background-color: rgba(20, 51, 80, .9)
}

.bg-5-gradient {
    background: -webkit-gradient(linear, left top, left bottom, from(#153654), to(#143350));
    background: linear-gradient(#153654, #143350)
}

.bg-5-gradient-start {
    background: #153654
}

.bg-6 {
    background-color: #f4f8fb
}

.bg-6.alpha-1 {
    background-color: rgba(244, 248, 251, .1)
}

.bg-6.alpha-2 {
    background-color: rgba(244, 248, 251, .2)
}

.bg-6.alpha-3 {
    background-color: rgba(244, 248, 251, .3)
}

.bg-6.alpha-4 {
    background-color: rgba(244, 248, 251, .4)
}

.bg-6.alpha-5 {
    background-color: rgba(244, 248, 251, .5)
}

.bg-6.alpha-6 {
    background-color: rgba(244, 248, 251, .6)
}

.bg-6.alpha-7 {
    background-color: rgba(244, 248, 251, .7)
}

.bg-6.alpha-8 {
    background-color: rgba(244, 248, 251, .8)
}

.bg-6.alpha-9 {
    background-color: rgba(244, 248, 251, .9)
}

.bg-6-gradient {
    background: -webkit-gradient(linear, left top, left bottom, from(#f8fafc), to(#f4f8fb));
    background: linear-gradient(#f8fafc, #f4f8fb)
}

.bg-6-gradient-start {
    background: #f8fafc
}

.b-l, .b-x {
    border-left: 1px solid #edf4f8
}

.b-r, .b-x {
    border-right: 1px solid #edf4f8
}

.b-t, .b-y {
    border-top: 1px solid #edf4f8
}

.b-b, .b-y {
    border-bottom: 1px solid #edf4f8
}

.b-1 {
    border-color: #fff
}

.b-2 {
    border-color: #646f79
}

.b-3 {
    border-color: #7b68ee
}

.b-4 {
    border-color: #017dc3
}

.b-5 {
    border-color: #143350
}

.b-6 {
    border-color: #f4f8fb
}

.circle {
    border-radius: 100%;
    border: none;
    box-shadow: 0 0 0 1px
}

.shadow {
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, .2)
}

.shadow-box {
    box-shadow: 0 0 1px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .03)
}

.browser {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #fff), color-stop(10%, #eef1f3));
    background: linear-gradient(#fff 1%, #eef1f3 10%);
    position: relative;
    padding-top: 2em;
    border-radius: .4em
}

.browser:before {
    display: block;
    position: absolute;
    content: '';
    top: .75em;
    left: 1em;
    width: .5em;
    height: .5em;
    border-radius: 50%;
    background: #f44;
    box-shadow: 0 0 0 1px #f44, 1.5em 0 0 1px #9b3, 3em 0 0 1px #fb5
}

.browser img {
    max-width: 100%
}

.browser.shadow {
    box-shadow: 0 0 1px rgba(0, 0, 0, .3), 0 2px 4px rgba(0, 0, 0, .09)
}

.browser.border {
    border: 1px solid #edf4f8
}

.fade-bottom:after {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(70%, #fff), to(#fff));
    background: linear-gradient(rgba(255, 255, 255, 0), #fff 70%, #fff);
    bottom: -25px;
    content: "";
    display: block;
    height: 85px;
    left: 0;
    position: absolute;
    right: 0
}

.list li+li {
    padding-top: 10px
}

.list.list-striped li:nth-of-type(2n+1) {
    background-color: rgba(64, 64, 64, .075)
}

.list.list-bordered li {
    border-top: 1px solid #edf4f8
}

.list.list-bordered li:last-child {
    border-bottom: 1px solid #edf4f8
}

.list.list-inline li {
    display: inline-block
}

blockquote {
    position: relative
}

blockquote:before {
    display: inline-block;
    font-family: FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\F10D";
    opacity: .5;
    font-size: 2rem;
    font-weight: 300;
    position: absolute;
    left: -16px;
    top: -16px
}

.blog-quote {
    margin-top: 3em;
    margin-bottom: 3em;
    padding: 0 1rem;
    position: relative
}

.blog-quote:before {
    font-size: 3.25rem;
    opacity: .2;
    left: 0
}

.blog-quote p {
    font-size: 2rem;
    line-height: 1.3em;
    font-weight: 300
}

@media (min-width:768px) {
    .blog-quote {
        padding: 0 4rem
    }
}

.fullscreen {
    min-height: 100vh
}

.overflow-hidden {
    overflow: hidden
}

.overlay {
    position: relative
}

.overlay:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(39, 51, 62, .6);
    opacity: 1;
    z-index: 0
}

.overlay [class^=container], .overlay>.content {
    position: relative;
    z-index: 1
}

.overlay>.content {
    position: relative
}

.overlay.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #27333e), to(rgba(39, 51, 62, .6)));
    background: linear-gradient(to left, #27333e 50%, rgba(39, 51, 62, .6))
}

.overlay.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #27333e), to(rgba(39, 51, 62, .6)));
    background: linear-gradient(to right, #27333e 50%, rgba(39, 51, 62, .6))
}

.overlay.overlay-1:after {
    background-color: rgba(255, 255, 255, .5)
}

.overlay.overlay-1.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fff), to(rgba(255, 255, 255, .6)));
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, .6))
}

.overlay.overlay-1.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), to(rgba(255, 255, 255, .6)));
    background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, .6))
}

.overlay.overlay-1.alpha-1:after {
    background-color: rgba(255, 255, 255, .1)
}

.overlay.overlay-1.alpha-1.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fff), to(rgba(255, 255, 255, .1)));
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, .1))
}

.overlay.overlay-1.alpha-1.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), to(rgba(255, 255, 255, .1)));
    background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, .1))
}

.overlay.overlay-1.alpha-2:after {
    background-color: rgba(255, 255, 255, .2)
}

.overlay.overlay-1.alpha-2.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fff), to(rgba(255, 255, 255, .2)));
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, .2))
}

.overlay.overlay-1.alpha-2.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), to(rgba(255, 255, 255, .2)));
    background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, .2))
}

.overlay.overlay-1.alpha-3:after {
    background-color: rgba(255, 255, 255, .3)
}

.overlay.overlay-1.alpha-3.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fff), to(rgba(255, 255, 255, .3)));
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, .3))
}

.overlay.overlay-1.alpha-3.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), to(rgba(255, 255, 255, .3)));
    background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, .3))
}

.overlay.overlay-1.alpha-4:after {
    background-color: rgba(255, 255, 255, .4)
}

.overlay.overlay-1.alpha-4.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fff), to(rgba(255, 255, 255, .4)));
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, .4))
}

.overlay.overlay-1.alpha-4.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), to(rgba(255, 255, 255, .4)));
    background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, .4))
}

.overlay.overlay-1.alpha-5:after {
    background-color: rgba(255, 255, 255, .5)
}

.overlay.overlay-1.alpha-5.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fff), to(rgba(255, 255, 255, .5)));
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, .5))
}

.overlay.overlay-1.alpha-5.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), to(rgba(255, 255, 255, .5)));
    background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, .5))
}

.overlay.overlay-1.alpha-6:after {
    background-color: rgba(255, 255, 255, .6)
}

.overlay.overlay-1.alpha-6.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fff), to(rgba(255, 255, 255, .6)));
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, .6))
}

.overlay.overlay-1.alpha-6.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), to(rgba(255, 255, 255, .6)));
    background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, .6))
}

.overlay.overlay-1.alpha-7:after {
    background-color: rgba(255, 255, 255, .7)
}

.overlay.overlay-1.alpha-7.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fff), to(rgba(255, 255, 255, .7)));
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, .7))
}

.overlay.overlay-1.alpha-7.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), to(rgba(255, 255, 255, .7)));
    background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, .7))
}

.overlay.overlay-1.alpha-8:after {
    background-color: rgba(255, 255, 255, .8)
}

.overlay.overlay-1.alpha-8.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fff), to(rgba(255, 255, 255, .8)));
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, .8))
}

.overlay.overlay-1.alpha-8.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), to(rgba(255, 255, 255, .8)));
    background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, .8))
}

.overlay.overlay-1.alpha-9:after {
    background-color: rgba(255, 255, 255, .9)
}

.overlay.overlay-1.alpha-9.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fff), to(rgba(255, 255, 255, .9)));
    background: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, .9))
}

.overlay.overlay-1.alpha-9.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #fff), to(rgba(255, 255, 255, .9)));
    background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, .9))
}

.overlay.overlay-2:after {
    background-color: rgba(100, 111, 121, .5)
}

.overlay.overlay-2.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .6)));
    background: linear-gradient(to left, #646f79 50%, rgba(100, 111, 121, .6))
}

.overlay.overlay-2.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .6)));
    background: linear-gradient(to right, #646f79 50%, rgba(100, 111, 121, .6))
}

.overlay.overlay-2.alpha-1:after {
    background-color: rgba(100, 111, 121, .1)
}

.overlay.overlay-2.alpha-1.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .1)));
    background: linear-gradient(to left, #646f79 50%, rgba(100, 111, 121, .1))
}

.overlay.overlay-2.alpha-1.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .1)));
    background: linear-gradient(to right, #646f79 50%, rgba(100, 111, 121, .1))
}

.overlay.overlay-2.alpha-2:after {
    background-color: rgba(100, 111, 121, .2)
}

.overlay.overlay-2.alpha-2.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .2)));
    background: linear-gradient(to left, #646f79 50%, rgba(100, 111, 121, .2))
}

.overlay.overlay-2.alpha-2.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .2)));
    background: linear-gradient(to right, #646f79 50%, rgba(100, 111, 121, .2))
}

.overlay.overlay-2.alpha-3:after {
    background-color: rgba(100, 111, 121, .3)
}

.overlay.overlay-2.alpha-3.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .3)));
    background: linear-gradient(to left, #646f79 50%, rgba(100, 111, 121, .3))
}

.overlay.overlay-2.alpha-3.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .3)));
    background: linear-gradient(to right, #646f79 50%, rgba(100, 111, 121, .3))
}

.overlay.overlay-2.alpha-4:after {
    background-color: rgba(100, 111, 121, .4)
}

.overlay.overlay-2.alpha-4.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .4)));
    background: linear-gradient(to left, #646f79 50%, rgba(100, 111, 121, .4))
}

.overlay.overlay-2.alpha-4.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .4)));
    background: linear-gradient(to right, #646f79 50%, rgba(100, 111, 121, .4))
}

.overlay.overlay-2.alpha-5:after {
    background-color: rgba(100, 111, 121, .5)
}

.overlay.overlay-2.alpha-5.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .5)));
    background: linear-gradient(to left, #646f79 50%, rgba(100, 111, 121, .5))
}

.overlay.overlay-2.alpha-5.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .5)));
    background: linear-gradient(to right, #646f79 50%, rgba(100, 111, 121, .5))
}

.overlay.overlay-2.alpha-6:after {
    background-color: rgba(100, 111, 121, .6)
}

.overlay.overlay-2.alpha-6.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .6)));
    background: linear-gradient(to left, #646f79 50%, rgba(100, 111, 121, .6))
}

.overlay.overlay-2.alpha-6.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .6)));
    background: linear-gradient(to right, #646f79 50%, rgba(100, 111, 121, .6))
}

.overlay.overlay-2.alpha-7:after {
    background-color: rgba(100, 111, 121, .7)
}

.overlay.overlay-2.alpha-7.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .7)));
    background: linear-gradient(to left, #646f79 50%, rgba(100, 111, 121, .7))
}

.overlay.overlay-2.alpha-7.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .7)));
    background: linear-gradient(to right, #646f79 50%, rgba(100, 111, 121, .7))
}

.overlay.overlay-2.alpha-8:after {
    background-color: rgba(100, 111, 121, .8)
}

.overlay.overlay-2.alpha-8.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .8)));
    background: linear-gradient(to left, #646f79 50%, rgba(100, 111, 121, .8))
}

.overlay.overlay-2.alpha-8.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .8)));
    background: linear-gradient(to right, #646f79 50%, rgba(100, 111, 121, .8))
}

.overlay.overlay-2.alpha-9:after {
    background-color: rgba(100, 111, 121, .9)
}

.overlay.overlay-2.alpha-9.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .9)));
    background: linear-gradient(to left, #646f79 50%, rgba(100, 111, 121, .9))
}

.overlay.overlay-2.alpha-9.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #646f79), to(rgba(100, 111, 121, .9)));
    background: linear-gradient(to right, #646f79 50%, rgba(100, 111, 121, .9))
}

.overlay.overlay-3:after {
    background-color: rgba(123, 104, 238, .5)
}

.overlay.overlay-3.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .6)));
    background: linear-gradient(to left, #7b68ee 50%, rgba(123, 104, 238, .6))
}

.overlay.overlay-3.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .6)));
    background: linear-gradient(to right, #7b68ee 50%, rgba(123, 104, 238, .6))
}

.overlay.overlay-3.alpha-1:after {
    background-color: rgba(123, 104, 238, .1)
}

.overlay.overlay-3.alpha-1.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .1)));
    background: linear-gradient(to left, #7b68ee 50%, rgba(123, 104, 238, .1))
}

.overlay.overlay-3.alpha-1.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .1)));
    background: linear-gradient(to right, #7b68ee 50%, rgba(123, 104, 238, .1))
}

.overlay.overlay-3.alpha-2:after {
    background-color: rgba(123, 104, 238, .2)
}

.overlay.overlay-3.alpha-2.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .2)));
    background: linear-gradient(to left, #7b68ee 50%, rgba(123, 104, 238, .2))
}

.overlay.overlay-3.alpha-2.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .2)));
    background: linear-gradient(to right, #7b68ee 50%, rgba(123, 104, 238, .2))
}

.overlay.overlay-3.alpha-3:after {
    background-color: rgba(123, 104, 238, .3)
}

.overlay.overlay-3.alpha-3.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .3)));
    background: linear-gradient(to left, #7b68ee 50%, rgba(123, 104, 238, .3))
}

.overlay.overlay-3.alpha-3.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .3)));
    background: linear-gradient(to right, #7b68ee 50%, rgba(123, 104, 238, .3))
}

.overlay.overlay-3.alpha-4:after {
    background-color: rgba(123, 104, 238, .4)
}

.overlay.overlay-3.alpha-4.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .4)));
    background: linear-gradient(to left, #7b68ee 50%, rgba(123, 104, 238, .4))
}

.overlay.overlay-3.alpha-4.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .4)));
    background: linear-gradient(to right, #7b68ee 50%, rgba(123, 104, 238, .4))
}

.overlay.overlay-3.alpha-5:after {
    background-color: rgba(123, 104, 238, .5)
}

.overlay.overlay-3.alpha-5.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .5)));
    background: linear-gradient(to left, #7b68ee 50%, rgba(123, 104, 238, .5))
}

.overlay.overlay-3.alpha-5.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .5)));
    background: linear-gradient(to right, #7b68ee 50%, rgba(123, 104, 238, .5))
}

.overlay.overlay-3.alpha-6:after {
    background-color: rgba(123, 104, 238, .6)
}

.overlay.overlay-3.alpha-6.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .6)));
    background: linear-gradient(to left, #7b68ee 50%, rgba(123, 104, 238, .6))
}

.overlay.overlay-3.alpha-6.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .6)));
    background: linear-gradient(to right, #7b68ee 50%, rgba(123, 104, 238, .6))
}

.overlay.overlay-3.alpha-7:after {
    background-color: rgba(123, 104, 238, .7)
}

.overlay.overlay-3.alpha-7.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .7)));
    background: linear-gradient(to left, #7b68ee 50%, rgba(123, 104, 238, .7))
}

.overlay.overlay-3.alpha-7.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .7)));
    background: linear-gradient(to right, #7b68ee 50%, rgba(123, 104, 238, .7))
}

.overlay.overlay-3.alpha-8:after {
    background-color: rgba(123, 104, 238, .8)
}

.overlay.overlay-3.alpha-8.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .8)));
    background: linear-gradient(to left, #7b68ee 50%, rgba(123, 104, 238, .8))
}

.overlay.overlay-3.alpha-8.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .8)));
    background: linear-gradient(to right, #7b68ee 50%, rgba(123, 104, 238, .8))
}

.overlay.overlay-3.alpha-9:after {
    background-color: rgba(123, 104, 238, .9)
}

.overlay.overlay-3.alpha-9.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .9)));
    background: linear-gradient(to left, #7b68ee 50%, rgba(123, 104, 238, .9))
}

.overlay.overlay-3.alpha-9.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #7b68ee), to(rgba(123, 104, 238, .9)));
    background: linear-gradient(to right, #7b68ee 50%, rgba(123, 104, 238, .9))
}

.overlay.overlay-4:after {
    background-color: rgba(1, 125, 195, .5)
}

.overlay.overlay-4.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .6)));
    background: linear-gradient(to left, #017dc3 50%, rgba(1, 125, 195, .6))
}

.overlay.overlay-4.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .6)));
    background: linear-gradient(to right, #017dc3 50%, rgba(1, 125, 195, .6))
}

.overlay.overlay-4.alpha-1:after {
    background-color: rgba(1, 125, 195, .1)
}

.overlay.overlay-4.alpha-1.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .1)));
    background: linear-gradient(to left, #017dc3 50%, rgba(1, 125, 195, .1))
}

.overlay.overlay-4.alpha-1.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .1)));
    background: linear-gradient(to right, #017dc3 50%, rgba(1, 125, 195, .1))
}

.overlay.overlay-4.alpha-2:after {
    background-color: rgba(1, 125, 195, .2)
}

.overlay.overlay-4.alpha-2.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .2)));
    background: linear-gradient(to left, #017dc3 50%, rgba(1, 125, 195, .2))
}

.overlay.overlay-4.alpha-2.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .2)));
    background: linear-gradient(to right, #017dc3 50%, rgba(1, 125, 195, .2))
}

.overlay.overlay-4.alpha-3:after {
    background-color: rgba(1, 125, 195, .3)
}

.overlay.overlay-4.alpha-3.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .3)));
    background: linear-gradient(to left, #017dc3 50%, rgba(1, 125, 195, .3))
}

.overlay.overlay-4.alpha-3.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .3)));
    background: linear-gradient(to right, #017dc3 50%, rgba(1, 125, 195, .3))
}

.overlay.overlay-4.alpha-4:after {
    background-color: rgba(1, 125, 195, .4)
}

.overlay.overlay-4.alpha-4.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .4)));
    background: linear-gradient(to left, #017dc3 50%, rgba(1, 125, 195, .4))
}

.overlay.overlay-4.alpha-4.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .4)));
    background: linear-gradient(to right, #017dc3 50%, rgba(1, 125, 195, .4))
}

.overlay.overlay-4.alpha-5:after {
    background-color: rgba(1, 125, 195, .5)
}

.overlay.overlay-4.alpha-5.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .5)));
    background: linear-gradient(to left, #017dc3 50%, rgba(1, 125, 195, .5))
}

.overlay.overlay-4.alpha-5.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .5)));
    background: linear-gradient(to right, #017dc3 50%, rgba(1, 125, 195, .5))
}

.overlay.overlay-4.alpha-6:after {
    background-color: rgba(1, 125, 195, .6)
}

.overlay.overlay-4.alpha-6.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .6)));
    background: linear-gradient(to left, #017dc3 50%, rgba(1, 125, 195, .6))
}

.overlay.overlay-4.alpha-6.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .6)));
    background: linear-gradient(to right, #017dc3 50%, rgba(1, 125, 195, .6))
}

.overlay.overlay-4.alpha-7:after {
    background-color: rgba(1, 125, 195, .7)
}

.overlay.overlay-4.alpha-7.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .7)));
    background: linear-gradient(to left, #017dc3 50%, rgba(1, 125, 195, .7))
}

.overlay.overlay-4.alpha-7.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .7)));
    background: linear-gradient(to right, #017dc3 50%, rgba(1, 125, 195, .7))
}

.overlay.overlay-4.alpha-8:after {
    background-color: rgba(1, 125, 195, .8)
}

.overlay.overlay-4.alpha-8.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .8)));
    background: linear-gradient(to left, #017dc3 50%, rgba(1, 125, 195, .8))
}

.overlay.overlay-4.alpha-8.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .8)));
    background: linear-gradient(to right, #017dc3 50%, rgba(1, 125, 195, .8))
}

.overlay.overlay-4.alpha-9:after {
    background-color: rgba(1, 125, 195, .9)
}

.overlay.overlay-4.alpha-9.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .9)));
    background: linear-gradient(to left, #017dc3 50%, rgba(1, 125, 195, .9))
}

.overlay.overlay-4.alpha-9.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #017dc3), to(rgba(1, 125, 195, .9)));
    background: linear-gradient(to right, #017dc3 50%, rgba(1, 125, 195, .9))
}

.overlay.overlay-5:after {
    background-color: rgba(20, 51, 80, .5)
}

.overlay.overlay-5.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #143350), to(rgba(20, 51, 80, .6)));
    background: linear-gradient(to left, #143350 50%, rgba(20, 51, 80, .6))
}

.overlay.overlay-5.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #143350), to(rgba(20, 51, 80, .6)));
    background: linear-gradient(to right, #143350 50%, rgba(20, 51, 80, .6))
}

.overlay.overlay-5.alpha-1:after {
    background-color: rgba(20, 51, 80, .1)
}

.overlay.overlay-5.alpha-1.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #143350), to(rgba(20, 51, 80, .1)));
    background: linear-gradient(to left, #143350 50%, rgba(20, 51, 80, .1))
}

.overlay.overlay-5.alpha-1.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #143350), to(rgba(20, 51, 80, .1)));
    background: linear-gradient(to right, #143350 50%, rgba(20, 51, 80, .1))
}

.overlay.overlay-5.alpha-2:after {
    background-color: rgba(20, 51, 80, .2)
}

.overlay.overlay-5.alpha-2.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #143350), to(rgba(20, 51, 80, .2)));
    background: linear-gradient(to left, #143350 50%, rgba(20, 51, 80, .2))
}

.overlay.overlay-5.alpha-2.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #143350), to(rgba(20, 51, 80, .2)));
    background: linear-gradient(to right, #143350 50%, rgba(20, 51, 80, .2))
}

.overlay.overlay-5.alpha-3:after {
    background-color: rgba(20, 51, 80, .3)
}

.overlay.overlay-5.alpha-3.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #143350), to(rgba(20, 51, 80, .3)));
    background: linear-gradient(to left, #143350 50%, rgba(20, 51, 80, .3))
}

.overlay.overlay-5.alpha-3.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #143350), to(rgba(20, 51, 80, .3)));
    background: linear-gradient(to right, #143350 50%, rgba(20, 51, 80, .3))
}

.overlay.overlay-5.alpha-4:after {
    background-color: rgba(20, 51, 80, .4)
}

.overlay.overlay-5.alpha-4.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #143350), to(rgba(20, 51, 80, .4)));
    background: linear-gradient(to left, #143350 50%, rgba(20, 51, 80, .4))
}

.overlay.overlay-5.alpha-4.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #143350), to(rgba(20, 51, 80, .4)));
    background: linear-gradient(to right, #143350 50%, rgba(20, 51, 80, .4))
}

.overlay.overlay-5.alpha-5:after {
    background-color: rgba(20, 51, 80, .5)
}

.overlay.overlay-5.alpha-5.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #143350), to(rgba(20, 51, 80, .5)));
    background: linear-gradient(to left, #143350 50%, rgba(20, 51, 80, .5))
}

.overlay.overlay-5.alpha-5.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #143350), to(rgba(20, 51, 80, .5)));
    background: linear-gradient(to right, #143350 50%, rgba(20, 51, 80, .5))
}

.overlay.overlay-5.alpha-6:after {
    background-color: rgba(20, 51, 80, .6)
}

.overlay.overlay-5.alpha-6.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #143350), to(rgba(20, 51, 80, .6)));
    background: linear-gradient(to left, #143350 50%, rgba(20, 51, 80, .6))
}

.overlay.overlay-5.alpha-6.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #143350), to(rgba(20, 51, 80, .6)));
    background: linear-gradient(to right, #143350 50%, rgba(20, 51, 80, .6))
}

.overlay.overlay-5.alpha-7:after {
    background-color: rgba(20, 51, 80, .7)
}

.overlay.overlay-5.alpha-7.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #143350), to(rgba(20, 51, 80, .7)));
    background: linear-gradient(to left, #143350 50%, rgba(20, 51, 80, .7))
}

.overlay.overlay-5.alpha-7.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #143350), to(rgba(20, 51, 80, .7)));
    background: linear-gradient(to right, #143350 50%, rgba(20, 51, 80, .7))
}

.overlay.overlay-5.alpha-8:after {
    background-color: rgba(20, 51, 80, .8)
}

.overlay.overlay-5.alpha-8.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #143350), to(rgba(20, 51, 80, .8)));
    background: linear-gradient(to left, #143350 50%, rgba(20, 51, 80, .8))
}

.overlay.overlay-5.alpha-8.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #143350), to(rgba(20, 51, 80, .8)));
    background: linear-gradient(to right, #143350 50%, rgba(20, 51, 80, .8))
}

.overlay.overlay-5.alpha-9:after {
    background-color: rgba(20, 51, 80, .9)
}

.overlay.overlay-5.alpha-9.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #143350), to(rgba(20, 51, 80, .9)));
    background: linear-gradient(to left, #143350 50%, rgba(20, 51, 80, .9))
}

.overlay.overlay-5.alpha-9.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #143350), to(rgba(20, 51, 80, .9)));
    background: linear-gradient(to right, #143350 50%, rgba(20, 51, 80, .9))
}

.overlay.overlay-6:after {
    background-color: rgba(244, 248, 251, .5)
}

.overlay.overlay-6.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .6)));
    background: linear-gradient(to left, #f4f8fb 50%, rgba(244, 248, 251, .6))
}

.overlay.overlay-6.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .6)));
    background: linear-gradient(to right, #f4f8fb 50%, rgba(244, 248, 251, .6))
}

.overlay.overlay-6.alpha-1:after {
    background-color: rgba(244, 248, 251, .1)
}

.overlay.overlay-6.alpha-1.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .1)));
    background: linear-gradient(to left, #f4f8fb 50%, rgba(244, 248, 251, .1))
}

.overlay.overlay-6.alpha-1.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .1)));
    background: linear-gradient(to right, #f4f8fb 50%, rgba(244, 248, 251, .1))
}

.overlay.overlay-6.alpha-2:after {
    background-color: rgba(244, 248, 251, .2)
}

.overlay.overlay-6.alpha-2.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .2)));
    background: linear-gradient(to left, #f4f8fb 50%, rgba(244, 248, 251, .2))
}

.overlay.overlay-6.alpha-2.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .2)));
    background: linear-gradient(to right, #f4f8fb 50%, rgba(244, 248, 251, .2))
}

.overlay.overlay-6.alpha-3:after {
    background-color: rgba(244, 248, 251, .3)
}

.overlay.overlay-6.alpha-3.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .3)));
    background: linear-gradient(to left, #f4f8fb 50%, rgba(244, 248, 251, .3))
}

.overlay.overlay-6.alpha-3.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .3)));
    background: linear-gradient(to right, #f4f8fb 50%, rgba(244, 248, 251, .3))
}

.overlay.overlay-6.alpha-4:after {
    background-color: rgba(244, 248, 251, .4)
}

.overlay.overlay-6.alpha-4.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .4)));
    background: linear-gradient(to left, #f4f8fb 50%, rgba(244, 248, 251, .4))
}

.overlay.overlay-6.alpha-4.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .4)));
    background: linear-gradient(to right, #f4f8fb 50%, rgba(244, 248, 251, .4))
}

.overlay.overlay-6.alpha-5:after {
    background-color: rgba(244, 248, 251, .5)
}

.overlay.overlay-6.alpha-5.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .5)));
    background: linear-gradient(to left, #f4f8fb 50%, rgba(244, 248, 251, .5))
}

.overlay.overlay-6.alpha-5.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .5)));
    background: linear-gradient(to right, #f4f8fb 50%, rgba(244, 248, 251, .5))
}

.overlay.overlay-6.alpha-6:after {
    background-color: rgba(244, 248, 251, .6)
}

.overlay.overlay-6.alpha-6.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .6)));
    background: linear-gradient(to left, #f4f8fb 50%, rgba(244, 248, 251, .6))
}

.overlay.overlay-6.alpha-6.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .6)));
    background: linear-gradient(to right, #f4f8fb 50%, rgba(244, 248, 251, .6))
}

.overlay.overlay-6.alpha-7:after {
    background-color: rgba(244, 248, 251, .7)
}

.overlay.overlay-6.alpha-7.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .7)));
    background: linear-gradient(to left, #f4f8fb 50%, rgba(244, 248, 251, .7))
}

.overlay.overlay-6.alpha-7.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .7)));
    background: linear-gradient(to right, #f4f8fb 50%, rgba(244, 248, 251, .7))
}

.overlay.overlay-6.alpha-8:after {
    background-color: rgba(244, 248, 251, .8)
}

.overlay.overlay-6.alpha-8.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .8)));
    background: linear-gradient(to left, #f4f8fb 50%, rgba(244, 248, 251, .8))
}

.overlay.overlay-6.alpha-8.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .8)));
    background: linear-gradient(to right, #f4f8fb 50%, rgba(244, 248, 251, .8))
}

.overlay.overlay-6.alpha-9:after {
    background-color: rgba(244, 248, 251, .9)
}

.overlay.overlay-6.alpha-9.gradient.to-left:after {
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .9)));
    background: linear-gradient(to left, #f4f8fb 50%, rgba(244, 248, 251, .9))
}

.overlay.overlay-6.alpha-9.gradient.to-right:after {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4f8fb), to(rgba(244, 248, 251, .9)));
    background: linear-gradient(to right, #f4f8fb 50%, rgba(244, 248, 251, .9))
}

.relative {
    position: relative
}

.absolute {
    position: absolute
}

@media (min-width:576px) {
    .absolute-sm {
        position: absolute
    }
}

@media (min-width:768px) {
    .absolute-md {
        position: absolute
    }
}

@media (min-width:992px) {
    .absolute-lg {
        position: absolute
    }
}

@media (min-width:1200px) {
    .absolute-xl {
        position: absolute
    }
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto
}

.row.gap-y>.col, .row.gap-y>[class*=col-] {
    padding-top: 15px;
    padding-bottom: 15px
}

.row.gap-2x>.col, .row.gap-2x>[class*=col-] {
    padding: 30px;
    padding: 30px
}

.m-6 {
    margin: 6rem!important
}

.mt-6 {
    margin-top: 6rem!important
}

.mr-6 {
    margin-right: 6rem!important
}

.mb-6 {
    margin-bottom: 6rem!important
}

.ml-6 {
    margin-left: 6rem!important
}

.mx-6 {
    margin-right: 6rem!important;
    margin-left: 6rem!important
}

.my-6 {
    margin-top: 6rem!important;
    margin-bottom: 6rem!important
}

.m-7 {
    margin: 7rem!important
}

.mt-7 {
    margin-top: 7rem!important
}

.mr-7 {
    margin-right: 7rem!important
}

.mb-7 {
    margin-bottom: 7rem!important
}

.ml-7 {
    margin-left: 7rem!important
}

.mx-7 {
    margin-right: 7rem!important;
    margin-left: 7rem!important
}

.my-7 {
    margin-top: 7rem!important;
    margin-bottom: 7rem!important
}

.m-8 {
    margin: 8rem!important
}

.mt-8 {
    margin-top: 8rem!important
}

.mr-8 {
    margin-right: 8rem!important
}

.mb-8 {
    margin-bottom: 8rem!important
}

.ml-8 {
    margin-left: 8rem!important
}

.mx-8 {
    margin-right: 8rem!important;
    margin-left: 8rem!important
}

.my-8 {
    margin-top: 8rem!important;
    margin-bottom: 8rem!important
}

.m-9 {
    margin: 9rem!important
}

.mt-9 {
    margin-top: 9rem!important
}

.mr-9 {
    margin-right: 9rem!important
}

.mb-9 {
    margin-bottom: 9rem!important
}

.ml-9 {
    margin-left: 9rem!important
}

.mx-9 {
    margin-right: 9rem!important;
    margin-left: 9rem!important
}

.my-9 {
    margin-top: 9rem!important;
    margin-bottom: 9rem!important
}

.p-6 {
    padding: 6rem!important
}

.pt-6 {
    padding-top: 6rem!important
}

.pr-6 {
    padding-right: 6rem!important
}

.pb-6 {
    padding-bottom: 6rem!important
}

.pl-6 {
    padding-left: 6rem!important
}

.px-6 {
    padding-right: 6rem!important;
    padding-left: 6rem!important
}

.py-6 {
    padding-top: 6rem!important;
    padding-bottom: 6rem!important
}

.p-7 {
    padding: 7rem!important
}

.pt-7 {
    padding-top: 7rem!important
}

.pr-7 {
    padding-right: 7rem!important
}

.pb-7 {
    padding-bottom: 7rem!important
}

.pl-7 {
    padding-left: 7rem!important
}

.px-7 {
    padding-right: 7rem!important;
    padding-left: 7rem!important
}

.py-7 {
    padding-top: 7rem!important;
    padding-bottom: 7rem!important
}

.p-8 {
    padding: 8rem!important
}

.pt-8 {
    padding-top: 8rem!important
}

.pr-8 {
    padding-right: 8rem!important
}

.pb-8 {
    padding-bottom: 8rem!important
}

.pl-8 {
    padding-left: 8rem!important
}

.px-8 {
    padding-right: 8rem!important;
    padding-left: 8rem!important
}

.py-8 {
    padding-top: 8rem!important;
    padding-bottom: 8rem!important
}

.p-9 {
    padding: 9rem!important
}

.pt-9 {
    padding-top: 9rem!important
}

.pr-9 {
    padding-right: 9rem!important
}

.pb-9 {
    padding-bottom: 9rem!important
}

.pl-9 {
    padding-left: 9rem!important
}

.px-9 {
    padding-right: 9rem!important;
    padding-left: 9rem!important
}

.py-9 {
    padding-top: 9rem!important;
    padding-bottom: 9rem!important
}

@media (min-width:576px) {
    .m-sm-6 {
        margin: 6rem!important
    }
    .mt-sm-6 {
        margin-top: 6rem!important
    }
    .mr-sm-6 {
        margin-right: 6rem!important
    }
    .mb-sm-6 {
        margin-bottom: 6rem!important
    }
    .ml-sm-6 {
        margin-left: 6rem!important
    }
    .mx-sm-6 {
        margin-right: 6rem!important;
        margin-left: 6rem!important
    }
    .my-sm-6 {
        margin-top: 6rem!important;
        margin-bottom: 6rem!important
    }
    .m-sm-7 {
        margin: 7rem!important
    }
    .mt-sm-7 {
        margin-top: 7rem!important
    }
    .mr-sm-7 {
        margin-right: 7rem!important
    }
    .mb-sm-7 {
        margin-bottom: 7rem!important
    }
    .ml-sm-7 {
        margin-left: 7rem!important
    }
    .mx-sm-7 {
        margin-right: 7rem!important;
        margin-left: 7rem!important
    }
    .my-sm-7 {
        margin-top: 7rem!important;
        margin-bottom: 7rem!important
    }
    .m-sm-8 {
        margin: 8rem!important
    }
    .mt-sm-8 {
        margin-top: 8rem!important
    }
    .mr-sm-8 {
        margin-right: 8rem!important
    }
    .mb-sm-8 {
        margin-bottom: 8rem!important
    }
    .ml-sm-8 {
        margin-left: 8rem!important
    }
    .mx-sm-8 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }
    .my-sm-8 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }
    .m-sm-9 {
        margin: 9rem!important
    }
    .mt-sm-9 {
        margin-top: 9rem!important
    }
    .mr-sm-9 {
        margin-right: 9rem!important
    }
    .mb-sm-9 {
        margin-bottom: 9rem!important
    }
    .ml-sm-9 {
        margin-left: 9rem!important
    }
    .mx-sm-9 {
        margin-right: 9rem!important;
        margin-left: 9rem!important
    }
    .my-sm-9 {
        margin-top: 9rem!important;
        margin-bottom: 9rem!important
    }
    .p-sm-6 {
        padding: 6rem!important
    }
    .pt-sm-6 {
        padding-top: 6rem!important
    }
    .pr-sm-6 {
        padding-right: 6rem!important
    }
    .pb-sm-6 {
        padding-bottom: 6rem!important
    }
    .pl-sm-6 {
        padding-left: 6rem!important
    }
    .px-sm-6 {
        padding-right: 6rem!important;
        padding-left: 6rem!important
    }
    .py-sm-6 {
        padding-top: 6rem!important;
        padding-bottom: 6rem!important
    }
    .p-sm-7 {
        padding: 7rem!important
    }
    .pt-sm-7 {
        padding-top: 7rem!important
    }
    .pr-sm-7 {
        padding-right: 7rem!important
    }
    .pb-sm-7 {
        padding-bottom: 7rem!important
    }
    .pl-sm-7 {
        padding-left: 7rem!important
    }
    .px-sm-7 {
        padding-right: 7rem!important;
        padding-left: 7rem!important
    }
    .py-sm-7 {
        padding-top: 7rem!important;
        padding-bottom: 7rem!important
    }
    .p-sm-8 {
        padding: 8rem!important
    }
    .pt-sm-8 {
        padding-top: 8rem!important
    }
    .pr-sm-8 {
        padding-right: 8rem!important
    }
    .pb-sm-8 {
        padding-bottom: 8rem!important
    }
    .pl-sm-8 {
        padding-left: 8rem!important
    }
    .px-sm-8 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }
    .py-sm-8 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }
    .p-sm-9 {
        padding: 9rem!important
    }
    .pt-sm-9 {
        padding-top: 9rem!important
    }
    .pr-sm-9 {
        padding-right: 9rem!important
    }
    .pb-sm-9 {
        padding-bottom: 9rem!important
    }
    .pl-sm-9 {
        padding-left: 9rem!important
    }
    .px-sm-9 {
        padding-right: 9rem!important;
        padding-left: 9rem!important
    }
    .py-sm-9 {
        padding-top: 9rem!important;
        padding-bottom: 9rem!important
    }
}

@media (min-width:768px) {
    .m-md-6 {
        margin: 6rem!important
    }
    .mt-md-6 {
        margin-top: 6rem!important
    }
    .mr-md-6 {
        margin-right: 6rem!important
    }
    .mb-md-6 {
        margin-bottom: 6rem!important
    }
    .ml-md-6 {
        margin-left: 6rem!important
    }
    .mx-md-6 {
        margin-right: 6rem!important;
        margin-left: 6rem!important
    }
    .my-md-6 {
        margin-top: 6rem!important;
        margin-bottom: 6rem!important
    }
    .m-md-7 {
        margin: 7rem!important
    }
    .mt-md-7 {
        margin-top: 7rem!important
    }
    .mr-md-7 {
        margin-right: 7rem!important
    }
    .mb-md-7 {
        margin-bottom: 7rem!important
    }
    .ml-md-7 {
        margin-left: 7rem!important
    }
    .mx-md-7 {
        margin-right: 7rem!important;
        margin-left: 7rem!important
    }
    .my-md-7 {
        margin-top: 7rem!important;
        margin-bottom: 7rem!important
    }
    .m-md-8 {
        margin: 8rem!important
    }
    .mt-md-8 {
        margin-top: 8rem!important
    }
    .mr-md-8 {
        margin-right: 8rem!important
    }
    .mb-md-8 {
        margin-bottom: 8rem!important
    }
    .ml-md-8 {
        margin-left: 8rem!important
    }
    .mx-md-8 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }
    .my-md-8 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }
    .m-md-9 {
        margin: 9rem!important
    }
    .mt-md-9 {
        margin-top: 9rem!important
    }
    .mr-md-9 {
        margin-right: 9rem!important
    }
    .mb-md-9 {
        margin-bottom: 9rem!important
    }
    .ml-md-9 {
        margin-left: 9rem!important
    }
    .mx-md-9 {
        margin-right: 9rem!important;
        margin-left: 9rem!important
    }
    .my-md-9 {
        margin-top: 9rem!important;
        margin-bottom: 9rem!important
    }
    .p-md-6 {
        padding: 6rem!important
    }
    .pt-md-6 {
        padding-top: 6rem!important
    }
    .pr-md-6 {
        padding-right: 6rem!important
    }
    .pb-md-6 {
        padding-bottom: 6rem!important
    }
    .pl-md-6 {
        padding-left: 6rem!important
    }
    .px-md-6 {
        padding-right: 6rem!important;
        padding-left: 6rem!important
    }
    .py-md-6 {
        padding-top: 6rem!important;
        padding-bottom: 6rem!important
    }
    .p-md-7 {
        padding: 7rem!important
    }
    .pt-md-7 {
        padding-top: 7rem!important
    }
    .pr-md-7 {
        padding-right: 7rem!important
    }
    .pb-md-7 {
        padding-bottom: 7rem!important
    }
    .pl-md-7 {
        padding-left: 7rem!important
    }
    .px-md-7 {
        padding-right: 7rem!important;
        padding-left: 7rem!important
    }
    .py-md-7 {
        padding-top: 7rem!important;
        padding-bottom: 7rem!important
    }
    .p-md-8 {
        padding: 8rem!important
    }
    .pt-md-8 {
        padding-top: 8rem!important
    }
    .pr-md-8 {
        padding-right: 8rem!important
    }
    .pb-md-8 {
        padding-bottom: 8rem!important
    }
    .pl-md-8 {
        padding-left: 8rem!important
    }
    .px-md-8 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }
    .py-md-8 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }
    .p-md-9 {
        padding: 9rem!important
    }
    .pt-md-9 {
        padding-top: 9rem!important
    }
    .pr-md-9 {
        padding-right: 9rem!important
    }
    .pb-md-9 {
        padding-bottom: 9rem!important
    }
    .pl-md-9 {
        padding-left: 9rem!important
    }
    .px-md-9 {
        padding-right: 9rem!important;
        padding-left: 9rem!important
    }
    .py-md-9 {
        padding-top: 9rem!important;
        padding-bottom: 9rem!important
    }
}

@media (min-width:992px) {
    .m-lg-6 {
        margin: 6rem!important
    }
    .mt-lg-6 {
        margin-top: 6rem!important
    }
    .mr-lg-6 {
        margin-right: 6rem!important
    }
    .mb-lg-6 {
        margin-bottom: 6rem!important
    }
    .ml-lg-6 {
        margin-left: 6rem!important
    }
    .mx-lg-6 {
        margin-right: 6rem!important;
        margin-left: 6rem!important
    }
    .my-lg-6 {
        margin-top: 6rem!important;
        margin-bottom: 6rem!important
    }
    .m-lg-7 {
        margin: 7rem!important
    }
    .mt-lg-7 {
        margin-top: 7rem!important
    }
    .mr-lg-7 {
        margin-right: 7rem!important
    }
    .mb-lg-7 {
        margin-bottom: 7rem!important
    }
    .ml-lg-7 {
        margin-left: 7rem!important
    }
    .mx-lg-7 {
        margin-right: 7rem!important;
        margin-left: 7rem!important
    }
    .my-lg-7 {
        margin-top: 7rem!important;
        margin-bottom: 7rem!important
    }
    .m-lg-8 {
        margin: 8rem!important
    }
    .mt-lg-8 {
        margin-top: 8rem!important
    }
    .mr-lg-8 {
        margin-right: 8rem!important
    }
    .mb-lg-8 {
        margin-bottom: 8rem!important
    }
    .ml-lg-8 {
        margin-left: 8rem!important
    }
    .mx-lg-8 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }
    .my-lg-8 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }
    .m-lg-9 {
        margin: 9rem!important
    }
    .mt-lg-9 {
        margin-top: 9rem!important
    }
    .mr-lg-9 {
        margin-right: 9rem!important
    }
    .mb-lg-9 {
        margin-bottom: 9rem!important
    }
    .ml-lg-9 {
        margin-left: 9rem!important
    }
    .mx-lg-9 {
        margin-right: 9rem!important;
        margin-left: 9rem!important
    }
    .my-lg-9 {
        margin-top: 9rem!important;
        margin-bottom: 9rem!important
    }
    .p-lg-6 {
        padding: 6rem!important
    }
    .pt-lg-6 {
        padding-top: 6rem!important
    }
    .pr-lg-6 {
        padding-right: 6rem!important
    }
    .pb-lg-6 {
        padding-bottom: 6rem!important
    }
    .pl-lg-6 {
        padding-left: 6rem!important
    }
    .px-lg-6 {
        padding-right: 6rem!important;
        padding-left: 6rem!important
    }
    .py-lg-6 {
        padding-top: 6rem!important;
        padding-bottom: 6rem!important
    }
    .p-lg-7 {
        padding: 7rem!important
    }
    .pt-lg-7 {
        padding-top: 7rem!important
    }
    .pr-lg-7 {
        padding-right: 7rem!important
    }
    .pb-lg-7 {
        padding-bottom: 7rem!important
    }
    .pl-lg-7 {
        padding-left: 7rem!important
    }
    .px-lg-7 {
        padding-right: 7rem!important;
        padding-left: 7rem!important
    }
    .py-lg-7 {
        padding-top: 7rem!important;
        padding-bottom: 7rem!important
    }
    .p-lg-8 {
        padding: 8rem!important
    }
    .pt-lg-8 {
        padding-top: 8rem!important
    }
    .pr-lg-8 {
        padding-right: 8rem!important
    }
    .pb-lg-8 {
        padding-bottom: 8rem!important
    }
    .pl-lg-8 {
        padding-left: 8rem!important
    }
    .px-lg-8 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }
    .py-lg-8 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }
    .p-lg-9 {
        padding: 9rem!important
    }
    .pt-lg-9 {
        padding-top: 9rem!important
    }
    .pr-lg-9 {
        padding-right: 9rem!important
    }
    .pb-lg-9 {
        padding-bottom: 9rem!important
    }
    .pl-lg-9 {
        padding-left: 9rem!important
    }
    .px-lg-9 {
        padding-right: 9rem!important;
        padding-left: 9rem!important
    }
    .py-lg-9 {
        padding-top: 9rem!important;
        padding-bottom: 9rem!important
    }
}

@media (min-width:1200px) {
    .m-xl-6 {
        margin: 6rem!important
    }
    .mt-xl-6 {
        margin-top: 6rem!important
    }
    .mr-xl-6 {
        margin-right: 6rem!important
    }
    .mb-xl-6 {
        margin-bottom: 6rem!important
    }
    .ml-xl-6 {
        margin-left: 6rem!important
    }
    .mx-xl-6 {
        margin-right: 6rem!important;
        margin-left: 6rem!important
    }
    .my-xl-6 {
        margin-top: 6rem!important;
        margin-bottom: 6rem!important
    }
    .m-xl-7 {
        margin: 7rem!important
    }
    .mt-xl-7 {
        margin-top: 7rem!important
    }
    .mr-xl-7 {
        margin-right: 7rem!important
    }
    .mb-xl-7 {
        margin-bottom: 7rem!important
    }
    .ml-xl-7 {
        margin-left: 7rem!important
    }
    .mx-xl-7 {
        margin-right: 7rem!important;
        margin-left: 7rem!important
    }
    .my-xl-7 {
        margin-top: 7rem!important;
        margin-bottom: 7rem!important
    }
    .m-xl-8 {
        margin: 8rem!important
    }
    .mt-xl-8 {
        margin-top: 8rem!important
    }
    .mr-xl-8 {
        margin-right: 8rem!important
    }
    .mb-xl-8 {
        margin-bottom: 8rem!important
    }
    .ml-xl-8 {
        margin-left: 8rem!important
    }
    .mx-xl-8 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }
    .my-xl-8 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }
    .m-xl-9 {
        margin: 9rem!important
    }
    .mt-xl-9 {
        margin-top: 9rem!important
    }
    .mr-xl-9 {
        margin-right: 9rem!important
    }
    .mb-xl-9 {
        margin-bottom: 9rem!important
    }
    .ml-xl-9 {
        margin-left: 9rem!important
    }
    .mx-xl-9 {
        margin-right: 9rem!important;
        margin-left: 9rem!important
    }
    .my-xl-9 {
        margin-top: 9rem!important;
        margin-bottom: 9rem!important
    }
    .p-xl-6 {
        padding: 6rem!important
    }
    .pt-xl-6 {
        padding-top: 6rem!important
    }
    .pr-xl-6 {
        padding-right: 6rem!important
    }
    .pb-xl-6 {
        padding-bottom: 6rem!important
    }
    .pl-xl-6 {
        padding-left: 6rem!important
    }
    .px-xl-6 {
        padding-right: 6rem!important;
        padding-left: 6rem!important
    }
    .py-xl-6 {
        padding-top: 6rem!important;
        padding-bottom: 6rem!important
    }
    .p-xl-7 {
        padding: 7rem!important
    }
    .pt-xl-7 {
        padding-top: 7rem!important
    }
    .pr-xl-7 {
        padding-right: 7rem!important
    }
    .pb-xl-7 {
        padding-bottom: 7rem!important
    }
    .pl-xl-7 {
        padding-left: 7rem!important
    }
    .px-xl-7 {
        padding-right: 7rem!important;
        padding-left: 7rem!important
    }
    .py-xl-7 {
        padding-top: 7rem!important;
        padding-bottom: 7rem!important
    }
    .p-xl-8 {
        padding: 8rem!important
    }
    .pt-xl-8 {
        padding-top: 8rem!important
    }
    .pr-xl-8 {
        padding-right: 8rem!important
    }
    .pb-xl-8 {
        padding-bottom: 8rem!important
    }
    .pl-xl-8 {
        padding-left: 8rem!important
    }
    .px-xl-8 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }
    .py-xl-8 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }
    .p-xl-9 {
        padding: 9rem!important
    }
    .pt-xl-9 {
        padding-top: 9rem!important
    }
    .pr-xl-9 {
        padding-right: 9rem!important
    }
    .pb-xl-9 {
        padding-bottom: 9rem!important
    }
    .pl-xl-9 {
        padding-left: 9rem!important
    }
    .px-xl-9 {
        padding-right: 9rem!important;
        padding-left: 9rem!important
    }
    .py-xl-9 {
        padding-top: 9rem!important;
        padding-bottom: 9rem!important
    }
}

.italic {
    font-style: italic
}

.underline {
    text-decoration: underline
}

.dotted {
    border-bottom: 1px dotted
}

.strike-through {
    text-decoration: line-through;
    color: #7d8993;
    font-weight: 300
}

.bold {
    font-weight: 700
}

.thin {
    font-weight: 100
}

.light {
    font-weight: 300
}

.regular {
    font-weight: 400
}

.extra-bold {
    font-weight: 900
}

.accent {
    color: #7b68ee
}

.color-1 {
    color: #fff
}

.color-1.alpha-1 {
    color: rgba(255, 255, 255, .1)
}

.color-1.alpha-2 {
    color: rgba(255, 255, 255, .2)
}

.color-1.alpha-3 {
    color: rgba(255, 255, 255, .3)
}

.color-1.alpha-4 {
    color: rgba(255, 255, 255, .4)
}

.color-1.alpha-5 {
    color: rgba(255, 255, 255, .5)
}

.color-1.alpha-6 {
    color: rgba(255, 255, 255, .6)
}

.color-1.alpha-7 {
    color: rgba(255, 255, 255, .7)
}

.color-1.alpha-8 {
    color: rgba(255, 255, 255, .8)
}

.color-1.alpha-9 {
    color: rgba(255, 255, 255, .9)
}

.color-2 {
    color: #646f79
}

.color-2.alpha-1 {
    color: rgba(100, 111, 121, .1)
}

.color-2.alpha-2 {
    color: rgba(100, 111, 121, .2)
}

.color-2.alpha-3 {
    color: rgba(100, 111, 121, .3)
}

.color-2.alpha-4 {
    color: rgba(100, 111, 121, .4)
}

.color-2.alpha-5 {
    color: rgba(100, 111, 121, .5)
}

.color-2.alpha-6 {
    color: rgba(100, 111, 121, .6)
}

.color-2.alpha-7 {
    color: rgba(100, 111, 121, .7)
}

.color-2.alpha-8 {
    color: rgba(100, 111, 121, .8)
}

.color-2.alpha-9 {
    color: rgba(100, 111, 121, .9)
}

.color-3 {
    color: #7b68ee
}

.color-3.alpha-1 {
    color: rgba(123, 104, 238, .1)
}

.color-3.alpha-2 {
    color: rgba(123, 104, 238, .2)
}

.color-3.alpha-3 {
    color: rgba(123, 104, 238, .3)
}

.color-3.alpha-4 {
    color: rgba(123, 104, 238, .4)
}

.color-3.alpha-5 {
    color: rgba(123, 104, 238, .5)
}

.color-3.alpha-6 {
    color: rgba(123, 104, 238, .6)
}

.color-3.alpha-7 {
    color: rgba(123, 104, 238, .7)
}

.color-3.alpha-8 {
    color: rgba(123, 104, 238, .8)
}

.color-3.alpha-9 {
    color: rgba(123, 104, 238, .9)
}

.color-4 {
    color: #017dc3
}

.color-4.alpha-1 {
    color: rgba(1, 125, 195, .1)
}

.color-4.alpha-2 {
    color: rgba(1, 125, 195, .2)
}

.color-4.alpha-3 {
    color: rgba(1, 125, 195, .3)
}

.color-4.alpha-4 {
    color: rgba(1, 125, 195, .4)
}

.color-4.alpha-5 {
    color: rgba(1, 125, 195, .5)
}

.color-4.alpha-6 {
    color: rgba(1, 125, 195, .6)
}

.color-4.alpha-7 {
    color: rgba(1, 125, 195, .7)
}

.color-4.alpha-8 {
    color: rgba(1, 125, 195, .8)
}

.color-4.alpha-9 {
    color: rgba(1, 125, 195, .9)
}

.color-5 {
    color: #143350
}

.color-5.alpha-1 {
    color: rgba(20, 51, 80, .1)
}

.color-5.alpha-2 {
    color: rgba(20, 51, 80, .2)
}

.color-5.alpha-3 {
    color: rgba(20, 51, 80, .3)
}

.color-5.alpha-4 {
    color: rgba(20, 51, 80, .4)
}

.color-5.alpha-5 {
    color: rgba(20, 51, 80, .5)
}

.color-5.alpha-6 {
    color: rgba(20, 51, 80, .6)
}

.color-5.alpha-7 {
    color: rgba(20, 51, 80, .7)
}

.color-5.alpha-8 {
    color: rgba(20, 51, 80, .8)
}

.color-5.alpha-9 {
    color: rgba(20, 51, 80, .9)
}

.color-6 {
    color: #f4f8fb
}

.color-6.alpha-1 {
    color: rgba(244, 248, 251, .1)
}

.color-6.alpha-2 {
    color: rgba(244, 248, 251, .2)
}

.color-6.alpha-3 {
    color: rgba(244, 248, 251, .3)
}

.color-6.alpha-4 {
    color: rgba(244, 248, 251, .4)
}

.color-6.alpha-5 {
    color: rgba(244, 248, 251, .5)
}

.color-6.alpha-6 {
    color: rgba(244, 248, 251, .6)
}

.color-6.alpha-7 {
    color: rgba(244, 248, 251, .7)
}

.color-6.alpha-8 {
    color: rgba(244, 248, 251, .8)
}

.color-6.alpha-9 {
    color: rgba(244, 248, 251, .9)
}

.color-smoke {
    color: #a7afb6
}

.lh-1 {
    line-height: 1
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: Lato, "Open Sans", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400
}

.font-regular {
    font-size: 1rem
}

.font-xs {
    font-size: .45rem
}

.font-sm, .small, small {
    font-size: .65rem
}

.font-md {
    font-size: 2rem
}

.font-lg {
    font-size: 3.25rem
}

.font-xl {
    font-size: 4.5rem
}

.font-xxl {
    font-size: 6.5rem
}

.font-l {
    font-size: 1.25rem
}

.mockup-wrapper {
    position: relative
}

.mockup {
    position: relative;
    margin: 0 auto;
    max-width: 300px
}

@media (min-width:768px) {
    .mockup {
        max-width: 478px
    }
}

.mockup img {
    display: block;
    max-width: 100%;
    height: auto
}

[class*=fa-] {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    margin-right: 5px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon {
    margin-left: 5px;
    margin-right: 15px;
    padding: 0;
    display: inline-block;
    text-align: center;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.icon.logo {
    max-width: 80px
}

.icon i {
    margin: 0
}

.icon-sm {
    width: .65rem;
    height: .65rem;
    line-height: .65rem;
    font-size: .2275rem
}

.icon-md {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: .7rem
}

.icon-lg {
    width: 3.25rem;
    height: 3.25rem;
    line-height: 3.25rem;
    font-size: 1.1375rem
}

.icon-xl {
    width: 4.5rem;
    height: 4.5rem;
    line-height: 4.5rem;
    font-size: 1.575rem
}

.icon-l {
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    font-size: .4375rem
}

.icon-1 {
    color: #41494f;
    background-color: #fff;
    border-color: #f2f2f2
}

.icon-1:hover {
    color: #41494f;
    background-color: #e6e6e6;
    border-color: #d4d4d4
}

.icon-2 {
    color: #fff;
    background-color: #646f79;
    border-color: #58626b
}

.icon-2:hover {
    color: #fff;
    background-color: #4d555d;
    border-color: #3d434a
}

.icon-3, .icon-accent {
    color: #fff;
    background-color: #7b68ee;
    border-color: #6751eb
}

.icon-3:hover, .icon-accent:hover {
    color: #fff;
    background-color: #533ae9;
    border-color: #371ae5
}

.icon-4 {
    color: #fff;
    background-color: #017dc3;
    border-color: #016daa
}

.icon-4:hover {
    color: #fff;
    background-color: #015c90;
    border-color: #01466d
}

.icon-5 {
    color: #fff;
    background-color: #143350;
    border-color: #0f263c
}

.icon-5:hover {
    color: #fff;
    background-color: #0a1927;
    border-color: #03070b
}

.list-icon {
    list-style-type: none
}

.list-icon li>* {
    display: table-cell
}

.list-icon li i {
    padding-right: 15px
}

.icon-block {
    padding: 15px;
    margin-bottom: 15px
}

.icon-block p {
    margin-top: 10px
}

img.icon {
    max-width: 54px
}

.section, section {
    position: relative
}

.section [class^=container], section [class^=container] {
    padding-top: 45px;
    padding-bottom: 45px;
    position: relative
}

@media (min-width:768px) {
    .section [class^=container], section [class^=container] {
        padding-top: 90px;
        padding-bottom: 90px
    }
}

.section .container-wide, section .container-wide {
    overflow-x: hidden
}

.section .container-wide .row, section .container-wide .row {
    margin-left: 0!important;
    margin-right: 0!important
}

.section-heading {
    margin-bottom: 60px;
    position: relative
}

.section-heading h2 {
    margin-top: 0;
    margin-bottom: 30px
}

.section-heading p {
    font-weight: 300
}

.singl-testimonial .singl-testimonial-image {
    min-height: 400px
}

.site-footer {
    font-size: .9em
}

.site-footer h4 {
    margin-bottom: 15px
}

.pricing p {
    line-height: 1.5em
}

.pricing .pricing-value {
    font-family: Lato, "Open Sans", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 3.25rem
}

.pricing .pricing-value .price {
    position: relative
}

.pricing .pricing-value .price:after, .pricing .pricing-value .price:before {
    font-size: .35em;
    font-weight: 300;
    font-style: italic
}

.pricing .pricing-value .price:before {
    position: absolute;
    content: '$';
    top: 10px;
    left: -15px
}

.pricing .pricing-value .price:after {
    position: relative;
    content: '/mo'
}

.pricing .pricing-value .price.annual:after {
    content: '/yr'
}

.pricing.heading .pricing-plan {
    padding: 0
}

.pricing.heading .pricing-plan .pricing-details {
    padding: 15px
}

.navigation.fixed-top~main .header {
    padding-top: 90px
}

.header {
    position: relative
}

.header .container {
    position: relative;
    margin: 0 auto
}

.header.fullscreen {
    padding-top: 90px
}

.header.fullscreen .mockup {
    max-width: 70%
}

.header .mockup-half {
    max-height: 350px
}

.header.page .container {
    padding-top: 90px;
    padding-bottom: 90px
}

p {
    margin: 1rem 0
}

.logo {
    max-width: 180px
}

.swiper-container .swiper-pagination-top {
    bottom: auto;
    top: 0
}

hr[class*=bg] {
    border-top: 0;
    height: 1px
}

.post-content h2, .post-content h4 {
    margin: 2.5rem 0 1rem 0;
    font-weight: 700
}

.post-content p:not(.lead) {
    line-height: 1.75;
    margin-top: 1rem;
    margin-bottom: 2rem
}

.post-content img {
    margin: 2.5rem auto
}

.post-content .shadow {
    box-shadow: 0 10px 30px rgba(0, 0, 0, .08)
}

.theme-selector {
    background-color: #edf1f2;
    border-right: 1px solid #d5dfe1;
    border-bottom: none;
    border-top: none;
    border-left: none;
    box-shadow: 1px 1px 5px rgba(180, 180, 180, .5);
    position: fixed;
    top: 0;
    left: 0;
    width: 350px;
    -webkit-transition: all .35s;
    transition: all .35s;
    z-index: 1111;
    color: #a0a0a0;
    border-radius: 0;
    height: 100vh
}

.theme-selector .card-body {
    overflow-y: auto
}

.theme-selector .flipper {
    background-color: #f98a5f;
    color: #edf1f2;
    padding: 15px;
    border-radius: 0 3px 3px 0;
    border-color: #d5dfe1;
    cursor: pointer;
    position: absolute;
    top: 40%;
    right: -49px;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.theme-selector ul.list li {
    padding-bottom: 15px
}

.theme-selector ul.list.page-variants li {
    position: relative
}

.theme-selector ul.list.page-variants li+li {
    padding-top: 15px;
    border-top: 1px solid #d5dfe1
}

.theme-selector ul.list.page-variants .mockup {
    max-width: 100%
}

.theme-selector ul.list.page-variants .mockup img {
    width: 100%
}

.theme-selector.selector-closed {
    left: -350px
}

.theme-selector.selector-closed .flipper {
    opacity: .85
}

.theme-selector.selector-closed .flipper:hover {
    opacity: 1
}

.theme-selector.selector-open {
    left: 0
}

.theme-selector.selector-open .flipper {
    opacity: 0
}
